import React from "react";
import "../../styles/ProjectDisplay.css";

const MLUNITYDescription = () => {
return (
    <div className="desc">
        <h2>Summary</h2>
        <p>My first project exploring reinforcement learning within interactive simulations, I developed a Unity-based Sumo Wrestling AI using the Machine Learning Agents Toolkit. The objective was to emulate sumo wrestling by training agents to push their opponents over or out of an arena. Positive rewards were assigned for successful pushes, while penalties discouraged falls, creating competitive and strategic behaviors.</p>
        <p>The complexity of the project lay in managing a substantial observation space of 236 dimensions alongside 39 continuous action parameters. By modifying the existing "Walker" example from the toolkit, I tailored the environment to suit the sumo wrestling context. Throughout the training process, I utilized TensorBoard to monitor the agents' performance, allowing for a visualization of the impressive improvement from the initial instability to something resembling actual wrestling. This project not only demonstrates proficiency in Unity and ML-Agents but also showcases the practical application of advanced machine learning concepts in a simulated environment.</p>
        <h2>Features</h2>
        <ul>
            <li>Reinforcement Learning Implementation</li>
            <li>Custom Reward System</li>
            <li>Performance Monitoring with TensorBoard</li>
            <li>Agent Evolution</li>
        </ul>
    </div>
);
};
export default MLUNITYDescription